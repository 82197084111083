<template>
  <v-breadcrumbs large class="pl-0" :items="truncatedItems" data-test="stemble-breadcrumbs">
    <template v-slot:divider> /</template>
    <template v-slot:item="{item}">
      <v-breadcrumbs-item
        v-if="item.text !== truncatedValue"
        exact-active-class="active-crumb v-breadcrumbs__item--disabled"
        v-bind="item"
        :href="item.href"
      >
        <span
          v-if="item.to && item.to.name !== currentRouteName"
          class="stemble-breadcrumb"
          v-text="item.text"
        />
        <h1 v-else class="stemble-breadcrumb" v-text="item.text" />
      </v-breadcrumbs-item>
      <a v-else class="stemble-breadcrumb" @click="viewTruncated = true">{{ item.text }}</a>
    </template>
  </v-breadcrumbs>
</template>

<script>
export default {
  name: 'StembleBreadcrumbs',
  props: {
    maxItems: {
      type: Number,
      default: 100,
    },
  },
  data() {
    return {
      viewTruncated: false,
      truncatedValue: '...',
    };
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    items() {
      const result = [];
      const params = this.$route.params;
      this.$route.matched.forEach((route) => {
        if (route.meta.breadcrumbs) {
          const name = this.getRouteName(route, params);
          const text = this.getRouteText(route, params) || name;
          const getHref = route.meta.breadcrumbs.getHref || (() => null);
          const href = getHref(params);
          let to;

          if (!href) {
            to = {params, ...(this.getRouteTo(route, params) || {name})};
          }

          // If route has a name and it isn't duplicated in the last entry, we add
          if (
            name !== undefined &&
            (result.length === 0 || text !== result[result.length - 1].text)
          ) {
            result.push({
              to,
              href,
              text,
              exact: true,
            });
          }
        }
      });
      return result;
    },
    reactiveMaxItems() {
      let nItems = 1;
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          nItems = 1;
          break;
        case 'sm':
          nItems = 2;
          break;
        case 'md':
          nItems = 3;
          break;
        case 'lg':
          nItems = 4;
          break;
        default:
          nItems = 5;
          break;
      }
      return Math.min(nItems, this.maxItems);
    },
    truncatedItems() {
      let items = this.items;
      if (items.length > this.reactiveMaxItems && !this.viewTruncated) {
        items = items.slice(items.length - this.reactiveMaxItems);
        items = [{text: this.truncatedValue}, ...items];
      }
      return items;
    },
  },
  watch: {
    items(newValue) {
      // Resets the truncated view once less than max items goes in
      if (newValue.length <= this.maxItems && this.viewTruncated) {
        this.viewTruncated = false;
      }
    },
  },
  methods: {
    getRouteName(route, params) {
      let name;
      const breadcrumbs = this.getRouteBreadcrumbs(route);
      if (breadcrumbs.name) {
        name = this.evaluateBreadcrumbData(breadcrumbs.name, {route, params});
      }
      if (!name) {
        name = route.name;
      }
      return name;
    },
    getRouteText(route, params) {
      let text;
      const breadcrumbs = this.getRouteBreadcrumbs(route);
      if (breadcrumbs.text) {
        text = this.evaluateBreadcrumbData(breadcrumbs.text, {route, params});
      }
      return text;
    },
    getRouteTo(route, params) {
      let to;
      const breadcrumbs = this.getRouteBreadcrumbs(route);
      if (breadcrumbs.to) {
        to = this.evaluateBreadcrumbData(breadcrumbs.to, {route, params});
      }
      return to;
    },
    getRouteBreadcrumbs(route) {
      let breadcrumbs = {};
      if (route.meta && route.meta.breadcrumbs) {
        breadcrumbs = route.meta.breadcrumbs;
      }
      return breadcrumbs;
    },
    evaluateBreadcrumbData(data, params) {
      if (typeof data === 'function') {
        data = data(params);
      }
      return data;
    },
  },
};
</script>

<style lang="scss" scoped>
.stemble-breadcrumb {
  font-size: 0.9em;
  font-weight: normal;
  color: #4f4f4f;
}

.stemble-breadcrumb:hover {
  text-decoration: underline;
}
</style>
