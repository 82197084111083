import {IStembleAxiosPromise} from '@/common/api/types/IStembleAxiosPromise';
import {IStembleAxiosResponse} from '@/common/api/types/IStembleAxiosResponse';

export class NoMoreDataError extends Error {
  constructor() {
    super('No more data');
  }
}

type ExecutorFunction<T = any> = (options: {
  page?: number;
  pageSize?: number;
}) => IStembleAxiosPromise<T>;

export interface PaginatorOptions {
  page?: number;
  pageSize?: number;
}

/**
 * Short-hand helper for creating a paginator and immediately streaming the data
 * @param {ExecutorFunction} executor
 * @param {PaginatorOptions} options
 */
export const paginate = (
  executor: ExecutorFunction<IStembleAxiosPromise>,
  options: PaginatorOptions = {}
) => {
  return new Paginator(executor, options).stream();
};

/**
 * Class that paginates compatible requests.
 */
export class Paginator<T extends IStembleAxiosPromise<T>> {
  executor: ExecutorFunction<T>;
  nextPage: number;
  previousPage: number | null;
  pageSize?: number;
  _currentPromise: Promise<any> | null;
  currentPage: number | null;
  lastPage: number | null;
  total: number | null;

  /**
   * Constructor
   *
   * The executor should be of the following signature:
   *   ({page, pageSize, handleResponse}) => some promise
   *
   * The executor function is responsible for accepting the page and pageSize,
   * and returning a Promise containing the data.
   *
   * handleResponse can be ignored if the promise returns the axios response.
   * Otherwise, it should be called with the axios response in order to set the
   * paginator's attributes
   *
   * @param {Function} executor the function that creates a Promise.
   * @param {Number} page
   * @param {Number} pageSize
   */
  constructor(executor: ExecutorFunction<T>, {page = 1, pageSize}: PaginatorOptions = {}) {
    this.executor = executor;
    this.nextPage = page ?? 1;
    this.previousPage = page >= 2 ? page - 1 : null;
    this.pageSize = pageSize;

    this._currentPromise = null;
    this.currentPage = null;
    this.lastPage = null;
    this.total = null;
  }

  /**
   * Send a request to get the next page and return the Promise
   * @param {Number|null} page the page to get
   * @param {Boolean} setCurrent if true, sets the internal page to that page, resetting the
   *    position of the paginator
   * @return {Promise}
   */
  async next(page: number | null = null, setCurrent = true) {
    if (page !== null && setCurrent) {
      this.nextPage = page;
    }
    if (this.nextPage === null && page === null) {
      throw new NoMoreDataError();
    }
    return this.getPage(page !== null ? page : this.nextPage);
  }

  /**
   * Get the previous page and return the Promise
   * @return {Promise}
   */
  async previous() {
    if (this.previousPage === null) {
      throw new NoMoreDataError();
    }
    return this.getPage(this.previousPage);
  }

  /**
   * Get a page using the executor function
   * @param {Number} page
   * @return {Promise}
   */
  async getPage(page: number) {
    return this.executor({
      page,
      pageSize: this.pageSize,
    }).then((response) => {
      this._handleResponse(response);
      return response;
    });
  }

  async then(...args: Parameters<IStembleAxiosPromise<T>['then']>) {
    return this.next().then(...args);
  }

  async catch(...args: Parameters<IStembleAxiosPromise<T>['catch']>) {
    return this.next().catch(...args);
  }

  async finally(...args: Parameters<IStembleAxiosPromise<T>['finally']>) {
    return this.next().finally(...args);
  }

  _handleResponse(response: IStembleAxiosResponse<T>) {
    if (response.meta) {
      const {currentPage, lastPage, total, perPage} = response.meta;
      this.currentPage = currentPage;
      this.lastPage = lastPage;
      this.total = total;

      this.nextPage = currentPage < lastPage ? currentPage + 1 : null;
      if (currentPage >= 2) {
        this.previousPage = currentPage - 1 <= lastPage ? currentPage - 1 : lastPage;
      } else {
        this.previousPage = null;
      }
      this.pageSize = perPage;
    }
  }

  /**
   * Gets page after page up to the maximum, returning an array of the responses
   *
   * This allows one to get all resources, but request it in batches.
   *
   * @param {Number} maxRequests
   * @param {Boolean} returnResponses
   * @param {Function} thenFunction
   * @return {Promise<void>}
   */
  async stream(maxRequests = 100, {returnResponses = true} = {}) {
    const results = [];
    let result;
    for (let i = 0; i < maxRequests; i++) {
      try {
        result = await this.next();
      } catch (err) {
        if (err instanceof NoMoreDataError || err?.message === 'No more data') {
          break;
        } else {
          throw err;
        }
      }

      if (returnResponses) {
        results.push(result);
      }
    }
    return returnResponses ? results : null;
  }
}
