import {makeGlobalSingleton} from '@/container';
import storeInstance from '@/store/store';
import {computed} from '@vue/composition-api';
import {Store} from 'vuex';
import {StembleState} from '@/store/types/StembleState';
import {AppMode} from '@/common/enums/AppMode';
import {isDevelopment} from '@/environment';

export const UseAppEnvironment = makeGlobalSingleton(() => makeUseAppEnvironment(storeInstance));

export function makeUseAppEnvironment(store: Store<StembleState>) {
  const environment = computed(() => store.state.appEnvironment.environment);
  const mode = computed(() => store.state.appEnvironment.mode);

  // TODO: dev tools don't seem to let us inspect root store state (at least not in Firefox), so add helpers
  if (isDevelopment()) {
    // @ts-ignore
    window.appMode = {
      set(env: AppMode) {
        store.commit('SET_APP_MODE', env);
      },
      reset() {
        store.commit('RESET_APP_MODE');
      },
    };
  }

  return {
    environment,
    mode,
  };
}
