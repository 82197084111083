import {makeGlobalSingleton} from '@/container';
import Course from '@/courses/models/Course';

export const DrawerCoursesService = makeGlobalSingleton(makeDrawerCoursesService);

function makeDrawerCoursesService() {
  /**
   * Partition an array of courses into two arrays: the first is top level courses or courses where their parent
   * is not provided in the courses array, and everything else (i.e. sub-courses that do have a parent present)
   * @param courses
   */
  function partitionCourses(courses: Course[]): [Course[], Course[]] {
    const courseIds = new Set(courses.map((c) => c.id));
    const filterFn = (c: Course) => c.isTopLevelCourse || !courseIds.has(c.topLevelCourseId);
    return [courses.filter(filterFn), courses.filter((c) => !filterFn(c))];
  }

  /**
   * Sort an array of Courses by a particular key in descending order, falling back to topLevelCourseId.
   * Sub-courses are always grouped witih their parent.
   * @param courses
   * @param key
   */
  function sortCourses(courses: Course[], key: keyof Course = 'startDate'): Course[] {
    const result = [...courses];
    const [rootCourses, subCourses] = partitionCourses(result);
    const subCourseByTopLevelCourseId = Object.fromEntries(
      subCourses.map((c) => [c.topLevelCourseId, c])
    );
    return (
      rootCourses
        // Sort root/parent-less courses by key
        .sort((c1, c2) => compareCourses(c1, c2, key))

        // Group sub-course instances with their parent, then concatenate them in order
        .map((c) => {
          const group = [c];
          if (subCourseByTopLevelCourseId[c.topLevelCourseId]) {
            group.push(subCourseByTopLevelCourseId[c.topLevelCourseId]);
          }
          return group;
        })
        .reduce((acc, courseGroup) => [...acc, ...courseGroup], [])
    );
  }

  /**
   * Sorting function for two courses in the drawer
   * @param c1
   * @param c2
   * @param key
   */
  function compareCourses(c1: Course, c2: Course, key: keyof Course = 'startDate') {
    if (c1[key] < c2[key]) {
      return 1;
    } else if (c1[key] > c2[key]) {
      return -1;
    }

    if (c1.topLevelCourseId < c2.topLevelCourseId) {
      return 1;
    } else if (c1.topLevelCourseId > c2.topLevelCourseId) {
      return -1;
    }

    return 0;
  }

  return {
    sortCourses,
  };
}
