<template>
  <v-list-group
    :prepend-icon="item.icon"
    :sub-group="subGroup"
    append-icon="mdi-menu-down"
    :group="item.group"
    :color="
      barColor !== 'rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.7)' ? 'white' : 'grey darken-1'
    "
    data-test="group"
    :data-test-id="item.title"
  >
    <template v-slot:activator>
      <v-list-item-icon v-if="text" class="v-list-item__icon--text" v-text="computedText" />

      <v-list-item-avatar
        v-else-if="item.avatar"
        class="align-self-center"
        color="rgba(45,163,220,0.5)"
      >
        <v-img :src="item.avatar" />
      </v-list-item-avatar>

      <v-list-item-content v-if="item.title || item.subtitle">
        <v-list-item-title v-text="item.title" />

        <v-list-item-subtitle v-text="item.subtitle" />
      </v-list-item-content>
    </template>

    <template v-for="(child, i) in children">
      <stemble-item-group
        v-if="child.children"
        :key="`sub-group-${i}`"
        :item="child"
        :group="child.group"
        sub-group
      />
      <item v-else :key="`item-${i}`" :item="child" />
    </template>
  </v-list-group>
</template>

<script>
// Utilities
import {mapState} from 'vuex';
import Item from '@/drawer/StembleItem';

export default {
  name: 'StembleItemGroup',
  components: {Item},
  inheritAttrs: false,

  props: {
    item: {
      type: Object,
      default: () => ({
        avatar: undefined,
        group: undefined,
        title: undefined,
        children: [],
      }),
    },
    subGroup: {
      type: Boolean,
      default: false,
    },
    text: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState(['barColor']),
    children() {
      return this.item.children;
    },
    computedText() {
      if (!this.item || !this.item.title) {
        return '';
      }

      let text = '';
      this.item.title.split(' ').forEach((val) => {
        text += val.substring(0, 1);
      });
      return text;
    },
  },
};
</script>

<style>
.v-list-group__activator p {
  margin-bottom: 0;
}
</style>
