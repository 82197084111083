<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    ref="navDrawer"
    v-model="drawer"
    class="stemble-drawer"
    :width="windowWidth > 1279 ? 300 : 256"
    :dark="true"
    :expand-on-hover="expandOnHover"
    mobile-breakpoint="960"
    app
    mini-variant-width="80"
    v-bind="$attrs"
  >
    <template v-slot:img="props">
      <v-img :gradient="`to bottom, ${barColor}`" v-bind="props" />
    </template>

    <div class="drawer-toggle-wrapper mr-3">
      <v-btn aria-label="Side Menu Toggle" class="drawer-toggle" @click="drawer = !drawer">
        <v-icon aria-role="presentation" class="drawer-toggle-icon"> mdi-backburger</v-icon>
      </v-btn>
    </div>

    <v-list aria-label="User Menu Dropdown" dense nav class="user-nav">
      <stemble-item-group :item="profile" />
    </v-list>

    <div class="main-nav">
      <v-list expand nav class="main-nav-list">
        <template v-for="(item, i) in computedItems">
          <stemble-item-group v-if="item.children" :key="`group-${i}`" :item="item" />
          <item v-else :key="`item-${i}`" :item="item" />
        </template>
      </v-list>
      <router-link :aria-label="$t('go-to-home-page')" to="/" class="sidebar-footer">
        <v-img :src="require('@/common/assets/img/logo-color-large.png')" contain height="40" />
      </router-link>
    </div>
  </v-navigation-drawer>
</template>

<script>
import {mapGetters, mapState} from 'vuex';
import {Course} from '@/courses/models';
import {
  COURSE_HOME,
  COURSE_SETTINGS,
  DASHBOARD,
  SUPER_ADMIN_ROOT,
  USER_PROFILE,
} from '@/router/route-names';
import moment from 'moment';
import StembleItemGroup from '@/drawer/StembleItemGroup';
import Item from '@/drawer/StembleItem';
import {DrawerCoursesService} from '@/drawer/services/DrawerCoursesService';
import {inject} from '@/container';
import {FeatureFlag} from '@/feature-flags/types/FeatureFlag';

export default {
  name: 'StembleDrawer',
  components: {Item, StembleItemGroup},
  props: {
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },
  computed: {
    ...mapState(['barColor']),
    ...mapGetters({
      courseDrewer: 'course',
    }),
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(val) {
        this.$store.commit('SET_DRAWER', val);
      },
    },
    baseItems() {
      const items = [
        {
          icon: 'mdi-view-dashboard',
          title: this.$t('dashboard'),
          to: {name: DASHBOARD},
        },
      ];
      return items;
    },

    drawerCoursesService() {
      return inject(DrawerCoursesService);
    },
    allCourses() {
      return Course.queryCoursesForUser(this.$auth.user.id, true).all();
    },
    upcomingAndActiveCourseItems() {
      // Active courses are sorted most recent start date first
      const courses = this.drawerCoursesService.sortCourses(this.allCourses, 'startDate');
      return courses
        .filter((course) => course.isUpcoming() || course.isActive())
        .map(this.makeCourseMenu);
    },
    endedCourseItems() {
      // Inactive courses are sorted with most recently finished courses at top
      const courses = this.drawerCoursesService.sortCourses(this.allCourses, 'endDate');
      const endedCourses = courses.filter((course) => course.hasEnded());

      const courseItems = endedCourses.map(this.makeCourseMenu);
      const endedCourseIds = endedCourses.map((c) => c.id);

      const groupRegex = this.$router
        .resolve({name: COURSE_HOME, params: {courseId: 'ids'}})
        // Replace must be done because router escapes pipe characters
        .href.replace('ids/', `(${endedCourseIds.join('|')})`);

      if (courseItems.length === 0) {
        return [];
      }

      return [
        {
          icon: 'mdi-history',
          title: this.$t('drawer.pastCourses'),
          group: new RegExp(groupRegex),
          exact: false,
          children: courseItems,
        },
      ];
    },
    superAdminMenu() {
      if (!this.$gate.can('view', 'SuperAdmin')) {
        return [];
      }

      return [
        {
          title: 'Create Task',
          href: '/tasks/create',
          icon: 'mdi-plus-circle',
        },
        {
          title: this.$t('super-admin'),
          icon: 'mdi-account-lock',
          href: this.$router.resolve({name: SUPER_ADMIN_ROOT}).href,
        },
      ];
    },
    newCourseItems() {
      if (
        // TODO: move this type of check into one spot to override certain feature flags for super-users
        (this.$features.isEnabled(FeatureFlag.CourseEditor) || this.$gate.isSuperUser()) &&
        this.$gate.can('create', 'Course')
      ) {
        return [
          {
            title: this.$t('drawer.createCourse'),
            href: '/courses/create',
            icon: 'mdi-book-plus',
          },
        ];
      }
      return [];
    },
    computedItems() {
      return [
        ...this.baseItems,
        ...this.upcomingAndActiveCourseItems,
        ...this.endedCourseItems,
        ...this.newCourseItems,
        ...this.superAdminMenu,
      ];
    },
    profile() {
      return {
        avatar: this.$auth.user.avatarUrl,
        title: this.$auth.user.fullName,
        group: this.$router.resolve({name: USER_PROFILE}).href,
        children: [
          {
            icon: 'mdi-account',
            title: this.$t('my-profile'),
            href: '/profile',
          },
          {
            icon: 'mdi-reload',
            title: this.$t('change-password'),
            href: '/password/edit',
          },
          {
            icon: 'mdi-exit-to-app',
            title: this.$t('log-out'),
            href: false,
            click: () => {
              this.$auth.logout();
              this.$router.push({name: 'login'});
            },
          },
        ],
      };
    },
    user() {
      return this.$auth.user;
    },
  },

  watch: {
    '$vuetify.breakpoint.smAndDown'(val) {
      this.$emit('update:expandOnHover', !val);
    },
    '$vuetify.breakpoint.mdAndUp'() {
      this.$emit('update:expandOnHover', false);
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    makeCourseMenu(course) {
      const params = {courseId: course.id};

      const children = [
        {
          title: this.$t('drawer.courseHome'),
          href: '/courses/' + course.id,
          icon: 'mdi-book-open-variant',
        },
      ];

      children.push({
        title: this.$t('drawer.assignments'),
        exact: false,
        icon: 'mdi-book-edit',
        href: `/courses/${course.id}/assignments`,
        external: true,
      });

      if (this.$gate.can('viewSomeGradesFor', 'Course', course.id)) {
        children.push({
          title: this.$t('drawer.grade-table'),
          href: `/courses/${course.id}/grades`,
          icon: this.$i('grades.gradeTable'),
        });
      }

      if (this.$gate.can('viewParticipantsPage', 'Course', course.id)) {
        children.push({
          title: this.$t('drawer.participants'),
          exact: false,
          icon: 'mdi-account-check',
          href: `/courses/${course.id}/participants`,
        });
      }

      if (this.$gate.can('viewSomeAnalyticsFor', 'Course', course.id)) {
        children.push({
          title: this.$t('drawer.analytics'),
          exact: false,
          icon: 'mdi-chart-bar',
          href: `/courses/${course.id}/analytics/tasks`,
        });
      }

      if (
        this.$features.isEnabled(FeatureFlag.CourseEditor) &&
        this.$gate.can('update', 'Course', course.id)
      ) {
        children.push({
          title: this.$t('drawer.courseSettings'),
          to: {
            name: COURSE_SETTINGS,
            params,
          },
          icon: this.$i('common.settings'),
        });
      }

      return {
        icon: 'mdi-book',
        title: course.courseCode,
        subtitle: moment(course.startDate).format('MMM YYYY'),
        exact: false,
        group: this.$router.resolve({name: COURSE_HOME, params}).href,
        children,
      };
    },
  },
};
</script>
<style scoped>
.drawer-toggle-wrapper {
  position: absolute;
  z-index: 30;
  top: 12px;
  right: 0;
  margin: 0 !important;
}

.drawer-toggle-wrapper .drawer-toggle {
  transition: all ease-out 150ms;
  background: transparent !important;
  border: none;
  box-shadow: none;
  padding: 0 !important;
  margin: 0 0 0 0 !important;
  min-width: 0 !important;
  width: 52px !important;
  height: 48px !important;
}

.drawer-toggle-wrapper .drawer-toggle-icon {
  font-size: 25px;
  color: white !important;
}
</style>
