









import PaymentRequest from '@/payments/models/PaymentRequest';
import {useAuthService} from '@/auth/services/authService';
import {USER_PROFILE} from '@/router/route-names';

export default {
  name: 'PaymentRequiredBanner',
  setup() {
    const auth = useAuthService();

    const paymentRequests = PaymentRequest.fullQuery({
      hasPaid: false,
      isExempt: false,
      filterEndedCourses: true,
    })
      .where('userId', auth.user.id)
      .all();

    let isUnpaid = paymentRequests.length > 0;

    return {
      isUnpaid: isUnpaid,
      USER_PROFILE,
    };
  },
};
