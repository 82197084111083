<template>
  <v-list-item
    :href="href"
    :rel="href && href !== '#' ? 'noopener' : undefined"
    :to="item.to"
    :exact="item.exact !== undefined ? item.exact : true"
    active-class="active-nav-item"
    :data-test="item.title"
    @click="item.click"
  >
    <div
      v-if="text || item.icon"
      class="item-icon"
      :class="text ? 'item-icon--text' : 'item-icon--icon'"
    >
      <span v-if="text">{{ computedText }}</span>
      <v-icon v-else-if="item.icon" v-text="item.icon" />
    </div>

    <v-list-item-content v-if="item.title || item.subtitle">
      <v-list-item-title>
        {{ item.title }}
        <sup v-if="item.superscript"> {{ item.superscript }}</sup>
      </v-list-item-title>
      <v-list-item-subtitle v-if="item.subtitle" v-text="item.subtitle" />
    </v-list-item-content>
  </v-list-item>
</template>
<script>
import Themeable from 'vuetify/lib/mixins/themeable';

export default {
  name: 'Item',

  mixins: [Themeable],

  props: {
    item: {
      type: Object,
      default: () => ({
        href: undefined,
        icon: undefined,
        subtitle: undefined,
        superscript: undefined,
        title: undefined,
        to: undefined,
      }),
    },
    text: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    computedText() {
      if (!this.item || !this.item.title) {
        return '';
      }

      let text = '';

      this.item.title.split(' ').forEach((val) => {
        text += val.substring(0, 1);
      });

      return text;
    },
    href() {
      return this.item.href || (!this.item.to ? '#' : undefined);
    },
  },
};
</script>
