<template>
  <v-footer id="dashboard-core-footer">
    <v-row justify="center" align="center" no-gutters>
      <v-col>
        <span id="app-version">v{{ appVersion }}</span>
      </v-col>
      <v-col cols="8" class="text-center">
        <v-btn color="primary" href="https://www.stemble.com/privacy-policy" target="_blank" text>
          {{ $t('footer.privacyPolicy') }}
        </v-btn>
        <v-btn
          color="primary"
          href="https://www.stemble.com/terms-and-conditions"
          target="_blank"
          text
        >
          {{ $t('footer.terms') }}
        </v-btn>
        <v-btn color="primary" href="https://www.stemble.com/contact" target="_blank" text>
          {{ $t('footer.contact') }}
        </v-btn>
      </v-col>
      <v-col class="text-end">
        <span class="black--text">
          &copy; {{ currentYear }} <a href="https://www.stemble.com">Stemble</a> Learning Inc.
        </span>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
import moment from 'moment';
import {getEnvironment} from '@/environment';

export default {
  name: 'DashboardFooter',
  computed: {
    appVersion() {
      return getEnvironment().buildSha.substring(0, 7);
    },
    currentYear() {
      return moment().format('YYYY');
    },
  },
};
</script>
