







import DashboardFooter from '@/dashboard/components/DashboardFooter.vue';
import {defineComponent} from '@vue/composition-api';
import {inject} from '@/container';
import {UseAppEnvironment} from '@/common/composables/UseAppEnvironment';
import {AppMode} from '@/common/enums/AppMode';

export default defineComponent({
  name: 'DashboardView',
  components: {
    DashboardFooter,
  },
  inheritAttrs: false,
  setup() {
    const appEnvironment = inject(UseAppEnvironment);

    return {
      AppMode,
      appMode: appEnvironment.mode,
    };
  },
});
